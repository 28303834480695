
















































































































import BaseComponent from '@/core/base.component';
import { Component, Watch } from 'vue-property-decorator';
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';

@Component({
  name: 'JustSalad',
  components: {
    videoPlayer,
  },
})
export default class JustSalad extends BaseComponent {
  private totalTime = 0;
  private isStart = false;
  private isEnded = false;
  private isPlaying = false;
  private isVideoClicked = false;
  private isVisibleChapter = true;
  private currentTime = 0;
  private timeInterval: any = null;
  private chapterList = [
    [
      {
        title: '재료 세척',
        trackList: [
          {
            subject: '재료 세척 방법',
            time: 16,
          },
          {
            subject: '채소 소독 방법',
            time: 79,
          },
        ],
      },
    ],
    [
      {
        title: '재료 손질',
        trackList: [
          {
            subject: '재료 컷팅 및 손질 방법',
            time: 84,
          },
        ],
      },
    ],
    [
      {
        title: '재료 조리',
        trackList: [
          {
            subject: '조리 방법(삶기, 볶기, 굽기, 튀기기)',
            time: 298,
          },
        ],
      },
    ],
    [
      {
        title: '재료 담기',
        trackList: [
          {
            subject: '버섯 샐러드',
            time: 483,
          },
          {
            subject: '리코타 샐러드',
            time: 538,
          },
          {
            subject: '두부병아리콩 샐러드',
            time: 565,
          },
          {
            subject: '닭가슴살 샐러드',
            time: 594,
          },
          {
            subject: '콥 샐러드',
            time: 614,
          },
          {
            subject: '콩고기 샐러드',
            time: 641,
          },
          {
            subject: '구운 연어스테이크 샐러드',
            time: 665,
          },
        ],
      },
      {
        title: '드레싱',
        trackList: [
          {
            subject: '드레싱 만드는 방법 및 담기',
            time: 683,
          },
        ],
      },
    ],
    [
      {
        title: '배달 포장',
        trackList: [
          {
            subject: '배달용 포장 방법',
            time: 695,
          },
        ],
      },
    ],
  ];

  private playerOptions = {
    controlBar: {
      volumePanel: true,
    },
    autoplay: false,
    language: 'en',
    playbackRates: [0.7, 1.0, 1.5, 2.0],
    sources: [
      {
        type: 'video/mp4',
        src: !this.isMobile
          ? 'https://kr.object.ncloudstorage.com/common-storage-pickcook/recipes/recipe_just_salad.mp4'
          : 'https://kr.object.ncloudstorage.com/common-storage-pickcook/recipes/recipe_just_salad_m.mp4',
      },
    ],
    poster:
      'https://kr.object.ncloudstorage.com/common-storage-pickcook/recipes/recipe_just_salad_thumb.jpg',
  };

  get player() {
    return (this.$refs.videoPlayer as any).player;
  }
  // private player = (this.$refs.videoPlayer as any).player;

  onPlayerLoadeddata(event?: EventTarget) {
    if (this.player.cache_) {
      this.totalTime = this.player.cache_.duration;
    }
  }

  onPlayerPlay(event?: EventTarget) {
    this.isStart = true;
    this.isPlaying = true;
    this.isEnded = false;
    this.onPlayerClicked();
  }

  onPlayerEnded(event?: EventTarget) {
    this.isEnded = true;
  }

  onPlayerPause(event?: EventTarget) {
    this.isPlaying = false;
    this.onPlayerClicked();
  }

  onPlayerClicked(event?: EventTarget) {
    if (this.isStart) {
      this.isVideoClicked = true;
      setTimeout(() => {
        this.isVideoClicked = false;
      }, 1000);
    }
  }

  onToggleChapter() {
    this.isVisibleChapter = !this.isVisibleChapter;
  }

  onMoveChapter(time: number) {
    this.player.currentTime(time);
    this.player.play();
  }

  onKeyup(key?: any) {
    const vol = 0.1;
    const time = 10;

    // press up
    if (key.keyCode === 38) {
      this.player.cache_.lastVolume !== 1
        ? (this.player.cache_.lastVolume += vol)
        : 1;
      this.player.volume(this.player.cache_.lastVolume);
    }

    // press down
    if (key.keyCode === 40) {
      this.player.cache_.lastVolume !== 0
        ? (this.player.cache_.lastVolume -= vol)
        : 1;
      this.player.volume(this.player.cache_.lastVolume);
    }

    // Press the left button
    if (key.keyCode === 37) {
      this.player.cache_.currentTime !== 0
        ? (this.player.cache_.currentTime -= time)
        : 1;
      this.player.currentTime(this.player.cache_.currentTime);
    }

    // right click
    if (key.keyCode === 39) {
      this.player.cache_.currentTime !== this.player.cache_.duration
        ? (this.player.cache_.currentTime += time)
        : 1;
      this.player.currentTime(this.player.cache_.currentTime);
    }

    // Press the space bar button
    if (key.keyCode === 32) {
      if (!this.player.paused()) {
        this.player.pause();
      } else {
        this.player.play();
      }
    }
  }

  onKeydown(key?: any) {
    // disableclick F12
    if (key.keyCode == 123) {
      key.preventDefault();
      key.returnValue = false;
    }
  }

  onStopCtx(event?: any) {
    // disable mouse right click
    event.preventDefault();
  }

  handleDebouncedResizing() {
    const screenWith = window.innerWidth;

    if (screenWith <= 992) {
      this.isVisibleChapter = true;
    }
  }

  created() {
    window.addEventListener('resize', this.handleDebouncedResizing);
  }

  mounted() {
    this.timeInterval = setInterval(() => {
      this.currentTime = this.player.cache_.currentTime;
    }, 300);
    this.player.on('touchstart', (event: any) => {
      if (event.target.tagName === 'VIDEO') {
        if (!this.player.paused()) {
          this.player.pause();
        } else {
          this.player.play();
        }
      }
    });
    this.handleDebouncedResizing();
    document.addEventListener('keyup', this.onKeyup);
    document.addEventListener('keydown', this.onKeydown);
  }

  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyup);
    document.removeEventListener('keydown', this.onKeydown);
    window.addEventListener('resize', this.handleDebouncedResizing);
    clearInterval(this.timeInterval);
  }
}
